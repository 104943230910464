import {
  ELSAssignmentDtoGradeType,
  ELSAssignmentEditorGradeOption,
  ELSAssignmentEditorGradeOptions
} from '@els/els-component-shared-ts-react';
import { isNil } from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  AssessmentStatusDto,
  AssignmentDto,
  AssignmentGradeType,
  AssignmentType
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { getDefaultDueDateString } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.utilities';
import { isAssignmentPastDateExtended } from '../../pages/bulk-edit-settings/bulk-edit-settings.utilities';
import { dueDateExtensionWarning } from '../../constants/migrated-constants';
import { AssignmentNotice } from './AssignmentNotice.component';
import { Messages } from '../../translations/message.models';
import { MAX_GRADE_POINTS } from './assignment-editor.constant';
import { EaqAssignmentType } from '../../pages/eaq-assignment-editor/eaq-assignment.constants';

export const defaultGradingOptions = ELSAssignmentEditorGradeOptions.map((option) => {
  return {
    ...option,
    toolTipText: null
  };
});

export const getDueDate = (
  assignment: Partial<AssignmentDto> = {},
  hasDueDate = false
): string => {
  if (!hasDueDate) {
    return undefined;
  }
  const availableDate = isNil(assignment.availableDate) ? moment().toString() : assignment.availableDate;
  const defaultDueDateFromAvailableDate = getDefaultDueDateString(availableDate);
  const isDueDateInThePast = moment(defaultDueDateFromAvailableDate).diff() < 0;
  const defaultDueDate = isDueDateInThePast ? getDefaultDueDateString() : defaultDueDateFromAvailableDate;
  return isNil(assignment.dueDate) ? defaultDueDate : moment(assignment.dueDate).toISOString();
};

export const getAssignmentGradeType = (
  assignment: Partial<AssignmentDto> = {},
  hasDueDate = false,
  hasAvailableDate = false,
): AssignmentGradeType => {
  if (!hasDueDate || !hasAvailableDate) {
    return AssignmentGradeType.NOT_GRADED;
  }
  return assignment.assignmentGradeType || AssignmentGradeType.NOT_GRADED;
};

export const checkAssignmentHasAvailableDate = (assignment: AssignmentDto): boolean => {
  return assignment && !isNil(assignment.availableDate);
};

export const isAssignmentStarted = (assignment: Partial<AssignmentDto>): boolean => {
  if (!assignment || !assignment.studentAssignments || !assignment.studentAssignments.length) {
    return false;
  }
  return assignment.studentAssignments.some(stuAssignment => stuAssignment.status !== AssessmentStatusDto.NOT_STARTED);
};

export const isAssignmentGraded = (assignment: Partial<AssignmentDto>): boolean => {
  if (!assignment) {
    return false;
  }
  return assignment.assignmentGradeType !== AssignmentGradeType.NOT_GRADED;
};

export const checkAssignmentHasDueDate = (assignment: AssignmentDto): boolean => {
  return assignment && !isNil(assignment.dueDate);
};

export const checkAssignmentIsAssigned = (assignment: AssignmentDto): boolean => {
  return checkAssignmentHasAvailableDate(assignment) && checkAssignmentHasDueDate(assignment);
};

export const checkAssignmentIsUnassigned = (assignment: AssignmentDto): boolean => {
  return !checkAssignmentHasAvailableDate(assignment) && !checkAssignmentHasDueDate(assignment);
};

export const getTextFromAssignmentGradeType = (assignmentGradeType: string): string => {
  const gradeOption = ELSAssignmentEditorGradeOptions.find(option => option.value === assignmentGradeType as typeof option.value);
  return gradeOption ? gradeOption.primaryText : '';
};

export const getAssignmentGradeTypeDisplay = (assignment: AssignmentDto): string => {
  if (!assignment || isNil(assignment.assignmentGradeType)) {
    return getTextFromAssignmentGradeType(AssignmentGradeType.NOT_GRADED);
  }
  return getTextFromAssignmentGradeType(assignment.assignmentGradeType);
};

export const getDisabledGradeTypes = (hasDueDate: boolean, isStarted: boolean): ELSAssignmentDtoGradeType[] => {

  if (isStarted || !hasDueDate) {
    return [
      ELSAssignmentDtoGradeType.NOT_GRADED,
      ELSAssignmentDtoGradeType.PASS_FAIL,
      ELSAssignmentDtoGradeType.SCORED
    ];
  }

  return [];
};

export const isValidWholeNumber = (value) => {
  return Number.isInteger(value) && !value.toString().includes('e');
};

export const isGraded = (assignment: Partial<AssignmentDto>): boolean => {
  if (!assignment || !assignment.assignmentGradeType) {
    return false;
  }

  return assignment.assignmentGradeType !== AssignmentGradeType.NOT_GRADED;
};

export const isValidGradePoints = (value) => {
  return isValidWholeNumber(value) && value > 0 && value <= MAX_GRADE_POINTS;
};

export const validatePositiveNumber = (value) => () => {
  return {
    id: 'positive-number',
    value,
    content: 'Number greater than 0 is required',
    isValid: isValidWholeNumber(value) && value > 0
  };
};

export const validateMaxNumber = (value) => () => {
  return {
    id: 'max-number',
    value,
    content: `Number must be less than ${MAX_GRADE_POINTS}`,
    isValid: !isValidWholeNumber(value) || value <= MAX_GRADE_POINTS
  };
};

export const updateGradePoints = (assignment: Partial<AssignmentDto>, gradePoints: number | string): Partial<AssignmentDto> => {
  const newAssignment = { ...assignment };
  // Here we don't validate to allow for editing of invalid gradePoints
  newAssignment.gradePoints = gradePoints as unknown as number;
  // Here we assume the assignment has 1 goal because all graded Courseware managed assignments have 1 goal
  // The goals control how the grade points get set instead of directly on the gradePoints property
  // This will change when the following ticket is completed https://elsevier.atlassian.net/browse/LEARN-1389
  // TODO: Do not update goals once LEARN-1389 is complete
  // Here we validate just to be extra careful to not save invalid grade points
  // eslint-disable-next-line no-lone-blocks
  {
    if (
      isValidGradePoints(newAssignment.gradePoints)
        && newAssignment.assignmentGoals
        && newAssignment.assignmentGoals.length === 1
        && !EaqAssignmentType.includes(assignment.assignmentType)
        && assignment.assignmentType !== AssignmentType.INTERACTIVE_REVIEW
    ) {
      newAssignment.assignmentGoals = [
        {
          ...newAssignment.assignmentGoals[0],
          goal: newAssignment.gradePoints
        }
      ];
    }
  }
  return newAssignment;
};

export const updateGradePointsOnGradeTypeChange = (assignment: Partial<AssignmentDto>, gradingOptions: ELSAssignmentEditorGradeOption[]): Partial<AssignmentDto> => {
  const gradingOption = gradingOptions.find((option: ELSAssignmentEditorGradeOption): boolean => {
    return (option.value === assignment.assignmentGradeType as unknown as ELSAssignmentDtoGradeType);
  });

  if (!gradingOption) {
    return assignment;
  }

  if (
    (isNil(assignment.gradePoints)
      || assignment.gradePoints === '' as unknown as number
      || assignment.gradePoints === 0)
    && [AssignmentGradeType.SCORED, AssignmentGradeType.PASS_FAIL].includes(assignment.assignmentGradeType)
  ) {
    return updateGradePoints(assignment, gradingOption.defaultGradePoints);
  }

  if (assignment.assignmentGradeType === AssignmentGradeType.NOT_GRADED) {
    return updateGradePoints(assignment, gradingOption.defaultGradePoints);
  }

  return assignment;
};

export const getDueDateNotice = (props: {
  existingAssignment: AssignmentDto;
  updatedAssignment: AssignmentDto;
  isNotPublished: boolean;
}) => {

  const {
    existingAssignment,
    updatedAssignment,
    isNotPublished,
  } = props;

  if (isNotPublished) {
    return (
      <div className="o-els-container">
        <AssignmentNotice>
          <p>Lessons that are not yet published can only have a visibility date.</p>
          <p>Lessons will be made available to students if the content is published and the visibility date is in the past.</p>
          <p>Due dates can be added to assignments when Lesson content is published.</p>
          <p>You will be notified on the Course Plan page when Lesson content is published.</p>
        </AssignmentNotice>
      </div>
    );
  }

  if (!existingAssignment || !existingAssignment.dueDate || !updatedAssignment || !updatedAssignment.dueDate) {
    return null;
  }

  if (!updatedAssignment.assignmentGradeType || updatedAssignment.assignmentGradeType === AssignmentGradeType.NOT_GRADED) {
    return null;
  }

  if (!isAssignmentPastDateExtended(existingAssignment, updatedAssignment)) {
    return null;
  }

  return (
    <div className="o-els-container">
      <AssignmentNotice>
        {dueDateExtensionWarning}
      </AssignmentNotice>
    </div>
  );
};

export const getGradingNotice = (props: {
  isAssignmentStarted: boolean;
  showGradePoints: boolean;
  messages: Messages;
}) => {

  const {
    showGradePoints,
    messages
  } = props;

  if (!props.isAssignmentStarted) {
    return null;
  }

  return (
    <div className="u-els-margin-bottom">
      <AssignmentNotice>
        {
          showGradePoints
            ? messages.GRADE_TYPE_AND_GRADE_POINTS_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT
            : messages.GRADE_TYPE_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT
        }
      </AssignmentNotice>
    </div>
  );
};
